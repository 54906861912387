import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { SignatureContent } from 'src/app/models/signaturecontent';
// import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-signinitials',
  templateUrl: './signinitials.component.html',
  styleUrls: ['./signinitials.component.css']
})
export class SignInitialsComponent implements OnInit {

  @ViewChild('sigpad1', { static: true }) signaturePad: SignaturePad;
  @Input() signatureContent: SignatureContent;
  @Input() clickToSignContent: SignatureContent;
  @Output() signatureChanged = new EventEmitter<SignatureContent>();

  modalActiveTab = 'typeit';
  modalVisible = false;

  // stores the new values entered in the modal
  bindContent = new SignatureContent();

  // stores the current values
  controlContent = new SignatureContent();
  controlInitialsSvgSafe: SafeResourceUrl;

  innerWidth: any;
  innerHeight: any;

  dialogMaxWidth: string;
  dialogMaxHeight: string;

  public signaturePadOptions: Object = {
    'minWidth' : 3,
    penColor: 'rgb(66, 133, 244)',
    backgroundColor: 'rgb(255, 255, 255)',
    canvasWidth: 300,  // was 450. Make sure to adjust line 13 in the html. Add 62
    canvasHeight: 170  // was 250
  };

  constructor(private sanitizer: DomSanitizer) {}

  isValid(): boolean {
    // console.log (this.signatureContent.instanceId + ':isValid()');
    return (this.containsData(this.controlContent.initialsText) || this.containsData(this.controlContent.initialsSvg));
  }

  ngOnInit() {
    // copy data from the parent passed from the data model through selector parameters.
    if (this.containsData(this.signatureContent.initialsSvg)) {
      this.controlContent.initialsSvg = this.signatureContent.initialsSvg;
      this.controlInitialsSvgSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.signatureContent.initialsSvg);
    }
    if (this.containsData(this.signatureContent.initialsText)) {
      this.controlContent.initialsText = this.signatureContent.initialsText;
    }
    if (this.containsData(this.signatureContent.fullnameText)) {
      this.controlContent.fullnameText = this.signatureContent.fullnameText;
    }

    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    this.dialogMaxWidth = '362px';
    // this.dialogMaxWidth = '100%';
    this.dialogMaxHeight = '100%';


  }

/*   showDialogMaximized(dialog: Dialog) {
    console.log('showDiaglogMaximized');

     setTimeout(() => {
      dialog.maximize();
    }, 0);
  } */

  tabswitch(value: string) {
    this.modalActiveTab = value;
  }

  onCancelModal() {
    this.modalVisible = false;
    this.signaturePad.clear();
  }

  onSaveModal() {
    this.modalVisible = false;
    if (!this.signaturePad.isEmpty()) { // User drew a signature.

      // set the modal values. full name and initials are set through two-way binding.
      this.bindContent.initialsSvg = this.signaturePad.toDataURL('image/svg+xml', 0.5);
      this.bindContent.initialsPng = this.signaturePad.toDataURL('image/png', 0.5);

      // update the control values with new values captured in the modal
      this.controlContent.initialsSvg = this.bindContent.initialsSvg;
      this.controlContent.initialsPng = this.bindContent.initialsPng;
      this.controlInitialsSvgSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.bindContent.initialsSvg);

    } else if (this.signaturePad.isEmpty() &&
               this.containsData(this.controlContent.initialsSvg)) { // User cleared previously saved signature.

      // update the control values with new values captured in the modal
      this.controlContent.initialsSvg = undefined;
      this.controlInitialsSvgSafe = undefined;

    }

    // update the control values with new values captured in the modal
    this.controlContent.fullnameText = this.bindContent.fullnameText.trim();
    this.controlContent.initialsText = this.bindContent.initialsText.trim();

    // emit changes to parent control
    this.signatureChanged.emit(this.controlContent);
  }

  drawClear(sigpad) {
    sigpad.clear();
  }

  open(content) {

    if (this.clickToSignContent !== undefined &&
         (this.containsData(this.clickToSignContent.initialsSvg) ||
          this.containsData(this.clickToSignContent.initialsText))) {

      this.controlContent = this.clickToSignContent;

      if (this.containsData(this.clickToSignContent.initialsSvg)) {
        this.controlInitialsSvgSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.clickToSignContent.initialsSvg);
      }

    } else {

      // copy the current values to the modal values.
      this.bindContent = this.controlContent;

      this.bindContent.fullnameText = this.signatureContent.fullnameText;
      this.bindContent.initialsText = this.signatureContent.initialsText;

      // if there's a signature, load it into the signature pad. Then set the tab to drawIt.
      if (this.containsData(this.bindContent.initialsSvg)) {
        this.signaturePad.fromDataURL(this.bindContent.initialsSvg);
        this.modalActiveTab = 'drawit';
      }

      // show the dialog
      this.modalVisible = true;
    }

  }

  containsData(value: string): boolean {
    return (value !== undefined && value !== null && value.length > 0);
  }

  setInitialsFromFullName() {
    if (!this.containsData(this.bindContent.fullnameText )) {
      this.bindContent.fullnameText  = '';
      return;
    }
    const nameArr = this.bindContent.fullnameText.split(' ');
    let initials = '';
    nameArr.forEach(name => {
      if (name.trim().length > 0) {
        initials += name.charAt(0).toLocaleUpperCase();
      }
    });
    this.bindContent.initialsText = initials;
  }


}
