/// <reference types="@types/googlemaps" />
import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { FormattedAddress } from 'src/app/models/contract';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[google-place-us]'
})
export class GooglePlacesUSDirective implements OnInit {
  @Output() selected: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;

  constructor(private elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.element);
    autocomplete.setComponentRestrictions({'country': ['US']});

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Emit the new address object for the updated place
      this.selected.emit(this.getFormattedAddress(autocomplete.getPlace()));
    });
  }

  getFormattedAddress(place: any): FormattedAddress {
    const fa: FormattedAddress = new FormattedAddress();
    fa.autocompletedAddress = place.formatted_address;
    // console.log(place);
    place.address_components.forEach(component => {
      component.types.forEach (type => {
        if (type === 'route') {
          fa.street = component.long_name;
        }
        if (type === 'street_number') {
          fa.streetNumber = component.long_name;
        }
        if (type === 'locality') {
          fa.cityTown = component.long_name;
        }
        if (type === 'administrative_area_level_1') {
          fa.stateProvince = component.short_name;
        }
        if (type === 'country') {
          fa.country = component.short_name;
        }
        if (type === 'postal_code') {
          fa.postalZip = component.long_name;
        }
      });
      fa.lat = Number(place.geometry.location.lat()).toFixed(6);
      fa.lng = Number(place.geometry.location.lng()).toFixed(6);
    });
    // console.log(fa);
    return fa;
  }

}


