import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ContactInfoComponent } from './components/contactinfo/contactinfo.component';
import { AuctionDetailsComponent } from './components/auctiondetails/auctiondetails.component';
import { ScheduleCatalogComponent } from './components/schedulecatalog/schedulecatalog.component';
import { ReferralDetailsComponent } from './components/referraldetails/referraldetails.component';
import { PaymentComponent } from './components/payment/payment.component';
import { SignContractComponent } from './components/signcontract/signcontract.component';
import { CompleteComponent } from './components/complete/complete.component';
import { ForwardedComponent } from './components/forwarded/forwarded.component';
import { VerifyAddressComponent } from './components/verifyaddress/verifyaddress.component';

const routes: Routes = [
  {
    path: '',
    component: ContactInfoComponent,
  },
  {
    path: 'contact',
    component: ContactInfoComponent,
  },
  {
    path: 'verifyaddress',
    component: VerifyAddressComponent,
  },
  {
    path: 'auction',
    component: AuctionDetailsComponent,
  },
  {
    path: 'schedule',
    component: ScheduleCatalogComponent,
  },
  {
    path: 'referral',
    component: ReferralDetailsComponent,
  },
  {
    path: 'payment',
    component: PaymentComponent,
  },
  {
    path: 'contract',
    component: SignContractComponent,
  },
  {
    path: 'complete',
    component: CompleteComponent,
  },
  {
    path: 'forwarded',
    component: ForwardedComponent,
  }

];

// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });
