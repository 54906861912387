import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { AngularWebStorageModule } from 'angular-web-storage';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { NgxMaskModule } from 'ngx-mask';

import { SignaturePadModule } from 'angular2-signaturepad';

import { HeaderComponent } from './components/header/header.component';
import { ContactInfoComponent } from './components/contactinfo/contactinfo.component';
import { VerifyAddressComponent } from './components/verifyaddress/verifyaddress.component';
import { AuctionDetailsComponent } from './components/auctiondetails/auctiondetails.component';
import { ScheduleCatalogComponent } from './components/schedulecatalog/schedulecatalog.component';
import { ReferralDetailsComponent } from './components/referraldetails/referraldetails.component';
import { PaymentComponent } from './components/payment/payment.component';
import { SignContractComponent } from './components/signcontract/signcontract.component';
import { SignInitialsComponent } from './components/signinitials/signinitials.component';
import { SignFullComponent } from './components/signfull/signfull.component';
import { CompleteComponent } from './components/complete/complete.component';
import { GooglePlacesUSDirective } from './directives/googleplaces-us.directive';
import { GooglePlacesCADirective } from './directives/googleplaces-ca.directive';

import { ReferralService } from './services/referral.service';
import { StateProvinceService } from './services/stateprovince.service';
import { ForwardedComponent } from './components/forwarded/forwarded.component';
import { CookieService } from './services/cookie.service';

@NgModule({
   declarations: [
      AppComponent,
      HeaderComponent,
      ContactInfoComponent,
      VerifyAddressComponent,
      AuctionDetailsComponent,
      ScheduleCatalogComponent,
      ReferralDetailsComponent,
      PaymentComponent,
      SignContractComponent,
      GooglePlacesUSDirective,
      GooglePlacesCADirective,
      SignInitialsComponent,
      SignFullComponent,
      CompleteComponent,
      ForwardedComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      HttpClientModule,
      FormsModule,
      AppRoutes,
      AngularWebStorageModule,
      SignaturePadModule,
      CalendarModule,
      DialogModule,
      FieldsetModule,
      ProgressSpinnerModule,
      NgxMaskModule.forRoot()
   ],
   providers: [
      StateProvinceService,
      ReferralService,
      CookieService
    ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
