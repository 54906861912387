import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuctionInfo,
         AuctionTypeEnum,
         PicUploadTypeEnum,
         AuctionCategoryEnum,
         AuctionPropertyEnum,
         Contract,
         ContractStatusEnum,
         ContactInfo} from 'src/app/models/contract';
import { ContractService } from 'src/app/services/contract.service';
import * as moment from 'moment';


@Component({
  selector: 'app-auctiondetails',
  templateUrl: './auctiondetails.component.html',
  styleUrls: ['./auctiondetails.component.css']
})
export class AuctionDetailsComponent implements OnInit {

  private sessionId: string;

  AuctionTypeEnum = AuctionTypeEnum;
  PicUploadTypeEnum = PicUploadTypeEnum;
  AuctionCategoryEnum = AuctionCategoryEnum;
  AuctionPropertyEnum = AuctionPropertyEnum;

  auctionData: AuctionInfo;
  contactData: ContactInfo;
  gonebyCalendarDate: Date;
  minDate: Date;
  maxDate: Date;
  disabledDates: Date[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contractSvc: ContractService) {
  }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  // Set the focus date of the calendar control to min date.
  ngOnInit() {

    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.router.navigate(['/']);
    }

    this.auctionData = this.contractSvc.ContractAuctionInfo || new AuctionInfo(); // null coalescing operator
    this.contactData = this.contractSvc.ContractContactInfo || new ContactInfo();

    this.minDate = moment(this.contractSvc.Contract.createdDate).add(14, 'd').toDate();
    this.maxDate = moment(this.contractSvc.Contract.createdDate).add(90, 'd').toDate();

    if (this.auctionData.goneByDate) {

      this.gonebyCalendarDate = null;
      const moment_goneByDate = moment(this.auctionData.goneByDate);

      // check to see if the goneByDate is still valid if it was previously saved. If it's still valid, then assign the goneByDate.
      if (this.auctionData.auctionType === 'maxsoldManaged') {
        if (moment_goneByDate.isSameOrAfter(moment().add(10, 'd')) && moment_goneByDate.isSameOrBefore(moment().add(90, 'd'))) {
          this.gonebyCalendarDate = new Date(this.auctionData.goneByDate);
        }

      } else {
        if (moment_goneByDate.isSameOrAfter(moment().add(4, 'd')) && moment_goneByDate.isSameOrBefore(moment().add(90, 'd'))) {
          this.gonebyCalendarDate = new Date(this.auctionData.goneByDate);
        }
      }

    }

  }

  onMaxsoldManagedClick() {
    this.minDate = moment(this.contractSvc.Contract.createdDate).add(14, 'd').toDate();
    this.maxDate = moment(this.contractSvc.Contract.createdDate).add(90, 'd').toDate();
    this.gonebyCalendarDate = null;
  }

  onSellerManagedClick() {
    this.minDate = moment(this.contractSvc.Contract.createdDate).add(4, 'd').toDate();
    this.maxDate = moment(this.contractSvc.Contract.createdDate).add(90, 'd').toDate();
    this.gonebyCalendarDate = null;
  }

  goToPrevious() {
    this.router.navigate(['/'], { queryParams: { sessionId: this.sessionId } });
  }

  onSubmit() {

    this.auctionData.goneByDate = moment.utc(this.gonebyCalendarDate).local().toDate();

    if(this.auctionData.appEmail){
     this.auctionData.appEmail = this.auctionData.appEmail.toLowerCase();
    }
    this.contractSvc.ContractAuctionInfo = this.auctionData;
    this.contractSvc.ContractStatus = ContractStatusEnum.AuctionInfoCaptured;
    this.contractSvc.SaveSession(this.sessionId);

    this.contractSvc.SavePartialContract().subscribe();

    if (this.auctionData.auctionType === 'maxsoldManaged') { // maxsold managed
      this.router.navigate(['/schedule'], { queryParams: { sessionId: this.sessionId } });
    } else { // seller managed
      this.router.navigate(['/referral'], { queryParams: { sessionId: this.sessionId } });
    }
  }
}
