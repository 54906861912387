import { Component, OnInit } from '@angular/core';
import { Contract } from 'src/app/models/contract';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractService } from 'src/app/services/contract.service';

@Component({
  selector: 'app-forwarded',
  templateUrl: './forwarded.component.html',
  styleUrls: ['./forwarded.component.css']
})
export class ForwardedComponent implements OnInit {

  private sessionId: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contractSvc: ContractService) { }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  ngOnInit() {
    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.router.navigate(['/']);
    }
  }
}
