export const environment = {
  production: false,  // azure deployment
  development: true, // uat is off
  apiendpoint: 'https://maxsoldcontractuatclientapi.azurewebsites.net/v1/',
  tagManagerId: 'GTM-NTLW36',
  mapsAPI: 'AIzaSyDKpD_fWfp0-Tw_SopAoNkmWvTrOaAwnuQ',
  firebase: {
    apiKey: 'AIzaSyB-xpGPQ18rzA1B7l9OOCvfOPqdk0akTwg',
    authDomain: 'clientuat.maxsold.com',
    projectId: 'maxsold-contracts-uat',
    storageBucket: 'maxsold-contracts-uat.appspot.com',
    messagingSenderId: '731925722063',
    appId: '1:731925722063:web:20d309872c0cb53b7cd8ba',
    measurementId: 'G-X29HHS5F2D'
  }
};
