import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { ContractService } from 'src/app/services/contract.service';
import { SignContractInfo,
         ContactInfo,
         AuctionInfo,
         AddressCountryEnum,
         AuctionTypeEnum,
         AuctionCategoryEnum,
         Contract,
         ContractStatusEnum} from 'src/app/models/contract';
import { SignatureContent } from 'src/app/models/signaturecontent';
import { SignInitialsComponent } from '../signinitials/signinitials.component';
import { SignFullComponent } from '../signfull/signfull.component';

@Component({
  selector: 'app-signcontract',
  templateUrl: './signcontract.component.html',
  styleUrls: ['./signcontract.component.css']
})
export class SignContractComponent implements OnInit {
  // @ViewChild('signinitialsTermination', { static: false }) signinitialsTermination: SignInitialsComponent;
  // @ViewChild('signinitialsFees', { static: false }) signinitialsFees: SignInitialsComponent;
  // @ViewChild('signinitialsRepresentationSellerNotBid', { static: false }) signinitialsRepresentationSellerNotBid: SignInitialsComponent;
  // @ViewChild('signinitialsMMSellerObligationsOrganize', { static: false }) signinitialsMMSellerObligationsOrganize: SignInitialsComponent;
  // @ViewChild('signinitialsMMSellerObligationsAccessPremises', { static: false }) signinitialsMMSellerObligationsAccessPremises: SignInitialsComponent;
  // @ViewChild('signinitialsMMSellerObligationsHonorSales', { static: false }) signinitialsMMSellerObligationsHonorSales: SignInitialsComponent;
  // @ViewChild('signinitialsSMSellerObligationsSetup', { static: false }) signinitialsSMSellerObligationsSetup: SignInitialsComponent;
  // @ViewChild('signinitialsSMSellerObligationsAccessPremises', { static: false }) signinitialsSMSellerObligationsAccessPremises: SignInitialsComponent;
  // @ViewChild('signinitialsSMSellerObligationsHonorSales', { static: false }) signinitialsSMSellerObligationsHonorSales: SignInitialsComponent;
  @ViewChild('sellersname', { static: false }) sellersname: ElementRef;
  @ViewChild('signfullSellersFullName', { static: false }) signfullSellersFullName: SignFullComponent;

  private sessionId: string;

  AddressCountryEnum = AddressCountryEnum;
  AuctionTypeEnum = AuctionTypeEnum;
  AuctionCategoryEnum = AuctionCategoryEnum;

  contactData: ContactInfo;
  auctionData: AuctionInfo;

  signcontractData: SignContractInfo;
  initialData = new SignatureContent();
  clickToSign: SignatureContent;
  fullSignature: SignatureContent;

  sending = false;

  label_honor = 'honour';
  label_catalog = 'catalogue';
  label_cataloging = 'cataloguing';
  label_Cataloging = 'Cataloguing';

  innerWidth: any;
  innerHeight: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contractSvc: ContractService) {
  }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  fillEmail() {
    this.signcontractData.forwardName = this.contactData.primaryContact.firstname + ' ' + this.contactData.primaryContact.lastname;
    this.signcontractData.forwardEmail = this.contactData.primaryContact.email;
  }

  ngOnInit() {

    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.router.navigate(['/']);
    }

    this.signcontractData = this.contractSvc.SignContractInfo || new SignContractInfo();
    this.initialData.fullnameText = this.signcontractData.fullnameText;
    this.initialData.fullnameSvg = this.signcontractData.fullnameSvg;
    this.initialData.initialsText = this.signcontractData.initialsText;
    this.initialData.initialsSvg = this.signcontractData.initialsSvg;
    this.contactData = this.contractSvc.ContractContactInfo;
    this.auctionData = this.contractSvc.ContractAuctionInfo;

    if (!this.signcontractData.dateOfSignature) {
      this.signcontractData.dateOfSignature = new Date();
    }

    if (this.contactData.auctionAddress.country === AddressCountryEnum.UnitedStates) {
      this.label_honor = 'honor';
      this.label_catalog = 'catalog';
      this.label_cataloging = 'cataloging';
      this.label_Cataloging = 'Cataloging';
    }

    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

  }

  goToPrevious() {
    this.router.navigate(['/payment'], { queryParams: { sessionId: this.sessionId } });
  }

  onInitialsChanged(signatureContent: SignatureContent) {
    if (signatureContent) {
      this.clickToSign = signatureContent;

      this.initialData.fullnameText = signatureContent.fullnameText;
      this.initialData.initialsText = signatureContent.initialsText;
    }
  }

  onFullSignatureChanged(signatureContent: SignatureContent) {
    if (signatureContent) {
      this.fullSignature = signatureContent;

      this.initialData.fullnameText = signatureContent.fullnameText;
      this.initialData.initialsText = signatureContent.initialsText;
    }
  }

  onForward(): boolean {
    // console.log ('onForward');
    this.sending = true;

    this.signcontractData.forwardName = `${this.signcontractData.forwardFirstName} ${this.signcontractData.forwardLastName}`

    this.contractSvc.SignContractInfo = this.signcontractData;

    this.contractSvc.ContractStatus = ContractStatusEnum.SentToClientForSignature;
    this.contractSvc.SaveSession(this.sessionId);

    this.contractSvc.ForwardToSign().subscribe(
      data => {
        // this.loaded = true; --- spinning button icon.
        // console.log('ForwardToSign-Complete');
        // console.log (data);
        this.router.navigate(['/forwarded'], { queryParams: { sessionId: this.sessionId } });
        this.sending = false;
      },
      error => {
          // console.log (error);
          this.sending = false;
      });

    return true;
  }

  onSubmit(): boolean {

    // console.log ('onSubmit');

    if (!this.isValid()) {
      return false;
    }

    this.signcontractData.fullnameText = this.initialData.fullnameText;
    this.signcontractData.fullnameSvg = this.fullSignature.fullnameSvg;
    this.signcontractData.fullnamePng = this.fullSignature.fullnamePng;
    // this.signcontractData.initialsText = this.initialData.initialsText;
    // this.signcontractData.initialsSvg = this.clickToSign.initialsSvg;
    // this.signcontractData.initialsPng = this.clickToSign.initialsPng;
    this.signcontractData.sellersFullName = this.sellersname.nativeElement.value;

    this.signcontractData.forwardName = `${this.signcontractData.forwardFirstName} ${this.signcontractData.forwardLastName}`
    this.contractSvc.SignContractInfo = this.signcontractData;
    this.contractSvc.ContractStatus = ContractStatusEnum.SignedByClient;
    this.contractSvc.SaveSession(this.sessionId);

    this.contractSvc.SavePartialContract().subscribe();

    this.router.navigate(['/complete'], { queryParams: { sessionId: this.sessionId } });
    return true;
  }

  isValid() {

    if (this.signfullSellersFullName.isValid()) {
          return true;
        }
      return false;
    }

}

