import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contract,
         ContactInfo,
         AuctionInfo,
         ScheduleInfo,
         ReferralInfo,
         PaymentInfo,
         SignContractInfo,
         ContractApiResponse,
         ContractStatusEnum,
         PaymentMethodEnum,
         Address,
         ContactDetail,
         ReferralSupportedByEnum,
         ReferralHearAboutUsEnum,
         AuctionTypeEnum,
         AuctionPropertyEnum} from '../models/contract';
import { Observable, of } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

import { SessionStorageService } from 'angular-web-storage';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    // 'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private _contract: Contract;

  constructor(private http: HttpClient, private sessionStorage: SessionStorageService) { }

  get Contract(): Contract {
    return this._contract;
  }

  set ContractStatus(status: ContractStatusEnum) {
    this._contract.contractStatus = status;
  }

  public CreateContract() {
    this._contract = new Contract();
    this.sessionStorage.clear();
  }

  //Get request to view completed contract using query token
  public ViewContract(token: string): Observable<Contract> {
    const endpointURL = environment.apiendpoint + 'ViewContract/' + token;
    return this.http.get<Contract>(endpointURL)
        .pipe(
          catchError(this.handleGetError('ViewContract', this._contract))
        );
  }

  //Get request to view partial contract using query token
  public ViewPartialContract(token: string): Observable<Contract> {
    const endpointURL = environment.apiendpoint + 'ViewPartialContract/' + token;
    return this.http.get<Contract>(endpointURL)
        .pipe(
          catchError(this.handleGetError('ViewPartialContract', this._contract))
        );
  }

  //Post request to "v1/SavePartialContract" to save a partial contract
  public SavePartialContract() {
    // console.log ('SavePartialContract');
    const endpointURL = environment.apiendpoint + 'SavePartialContract';
    return this.http.post<ContractApiResponse>(endpointURL, this._contract, httpOptions)
        .pipe(
          catchError(this.handlePostError('SavePartialContract'))
        );
  }

  //Post request to "v1/ForwardToSign" to forward the contract to a new email
  public ForwardToSign(): Observable<ContractApiResponse> {
    // console.log('ForwardToSign');

    const endpointURL = environment.apiendpoint + 'ForwardToSign/';
    return this.http.post<ContractApiResponse>(endpointURL, this._contract, httpOptions)
        .pipe(delay (300))
        .pipe(
          catchError(this.handlePostError('ForwardToSign'))
        );
  }

  //Post request to "v1/SaveAfterSignature" to save after final submission
  public SaveAfterSignature(): Observable<ContractApiResponse> {
    const endpointURL = environment.apiendpoint + 'SaveAfterSignature';
    return this.http.post<ContractApiResponse>(endpointURL, this._contract, httpOptions)
        .pipe(
          catchError(this.handlePostError('SaveAfterSignature'))
        );
  }

  public ClearSession(sessionId: string) {
    if (!sessionId) {
      return;
    }
    this._contract = new Contract();
    this.sessionStorage.remove(sessionId);
  }

  //Save session after submission on each page
  public SaveSession(sessionId: string) {
    if (!sessionId) {
      return;
    }
    if (!this._contract)  {
      return;
    }
    this.sessionStorage.set(sessionId, this._contract, 7, 'd');
  }

  //Save session for already existing contracts (used when a contract is forwarded to sign)
  public SaveSessionWithContract(sessionId: string, contractItem: Contract) {
    if (!sessionId) {
      return;
    }
    if (!contractItem)  {
      return;
    }
    this._contract = contractItem;
    this.sessionStorage.set(sessionId, contractItem, 7, 'd');
  }

 //Load session on navigation to new page in the contract flow
  public LoadSession(sessionId: string): boolean {
    if (sessionId) {
      this._contract = this.sessionStorage.get(sessionId);
      if (this._contract) {
        return true;
      }
    }
    return false;
  }


  set ContractContactInfo(contactInfo: ContactInfo) {

    if (!contactInfo.hasSecondaryContact) {
      contactInfo.secondaryContact = new ContactDetail(); // Clear it
    }

    this._contract.contactInfo = contactInfo;
  }
  get ContractContactInfo(): ContactInfo {
    return this._contract.contactInfo;
  }

  set ContractAuctionInfo(auctionInfo: AuctionInfo) {

    if (auctionInfo.auctionType === AuctionTypeEnum.MaxsoldManaged) {
      auctionInfo.picuploadType = undefined;
      auctionInfo.numberOfLots = undefined;
    }

    if (auctionInfo.auctionProperty !== AuctionPropertyEnum.CondoApartment &&
      auctionInfo.auctionProperty !== AuctionPropertyEnum.Storage) {
        auctionInfo.hoaMgmtApproved = undefined;
    }

    this._contract.auctionInfo = auctionInfo;
  }
  get ContractAuctionInfo(): AuctionInfo {
    return this._contract.auctionInfo;
  }

  set ContractScheduleInfo(scheduleInfo: ScheduleInfo) {

    // If an option is selected, and a field is filled in, then a different option is selected, clear that data.
    if (!scheduleInfo.isGatedCommunity) {
      scheduleInfo.temporaryGateCode = undefined;
    }

    // Removed in 1.4 scheduleInfo.highValueDesc = this.CleanString(scheduleInfo.highValueDesc);
    scheduleInfo.propertyAccessDesc = this.CleanString(scheduleInfo.propertyAccessDesc);
    scheduleInfo.temporaryGateCode = this.CleanString(scheduleInfo.temporaryGateCode);

    this._contract.scheduleInfo = scheduleInfo;
  }

  get ContractScheduleInfo(): ScheduleInfo {
    return this._contract.scheduleInfo;
  }

  set ReferralCode(code: string) {
    this._contract.referralInfo.hearAboutUsReferralCode = code;
  }

  set ContractReferralInfo(referralInfo: ReferralInfo) {

    // If an option is selected, and a field is filled in, then a different option is selected, clear that data.
    // if (referralInfo.supportedBy === ReferralSupportedByEnum.Myself) {
    //   referralInfo.supportedByContact = new ContactDetail(); // Clear it
    //   referralInfo.supportedByCompany = undefined;
    //   referralInfo.supportedByMaxsoldRepId = undefined;
    // } else if (referralInfo.supportedBy === ReferralSupportedByEnum.MaxSoldRep) {
    //   referralInfo.supportedByCompany = undefined;
    // } else {
    //   referralInfo.supportedByMaxsoldRepId = undefined;
    // }

    if (referralInfo.hearAboutUs === ReferralHearAboutUsEnum.Other) {
      referralInfo.hearAboutUsOtherDesc = this.CleanString(referralInfo.hearAboutUsOtherDesc);
    } else {
      referralInfo.supportedByCompany = this.CleanString(referralInfo.supportedByCompany);
      referralInfo.hearAboutUsOtherDesc = undefined;
    }

    if (referralInfo.hearAboutUsReferralCode) {
      referralInfo.hearAboutUsReferralCode = this.CleanString(referralInfo.hearAboutUsReferralCode.toUpperCase());
    }

    this._contract.referralInfo = referralInfo;
  }
  get ContractReferralInfo(): ReferralInfo {
    return this._contract.referralInfo;
  }

  set ContractPaymentInfo(paymentInfo: PaymentInfo) {

    if (paymentInfo.paymentMethod === PaymentMethodEnum.CanadaPaymentInfoOnFile ||
      paymentInfo.paymentMethod === PaymentMethodEnum.UnitedStatesPaymentInfoOnFile ) {

      paymentInfo.checkNameCompany = undefined;
      paymentInfo.checkAddToTheEstateOf = false;
      paymentInfo.checkCareOf = undefined;
      paymentInfo.checkSameAsAuctionAddress = false;
      paymentInfo.checkMailingAddress = new Address();
      paymentInfo.checkMailingFormattedAddress = undefined;
      paymentInfo.efteddNameOnAccount = undefined;
      paymentInfo.eftFinancialInstitutionNumber = undefined;
      paymentInfo.eftTransitNumber = undefined;
      paymentInfo.eftAccountNumber = undefined;
      paymentInfo.eddRoutingNumber = undefined;
      paymentInfo.eddAccountNumber = undefined;
    } else if (paymentInfo.paymentMethod === PaymentMethodEnum.CanadaETF) {

      paymentInfo.checkNameCompany = undefined;
      paymentInfo.checkAddToTheEstateOf = false;
      paymentInfo.checkCareOf = undefined;
      paymentInfo.checkSameAsAuctionAddress = false;
      paymentInfo.checkMailingAddress = new Address();
      paymentInfo.checkMailingFormattedAddress = undefined;
      paymentInfo.efteddNameOnAccount = this.CleanString(paymentInfo.efteddNameOnAccount);
      paymentInfo.eftFinancialInstitutionNumber = this.CleanString(paymentInfo.eftFinancialInstitutionNumber);
      paymentInfo.eftTransitNumber = this.CleanString(paymentInfo.eftTransitNumber);
      paymentInfo.eftAccountNumber = this.CleanString(paymentInfo.eftAccountNumber);
      paymentInfo.eddRoutingNumber = undefined;
      paymentInfo.eddAccountNumber = undefined;
    } else if (paymentInfo.paymentMethod === PaymentMethodEnum.UnitedStatesEDD) {

      paymentInfo.checkNameCompany = undefined;
      paymentInfo.checkAddToTheEstateOf = false;
      paymentInfo.checkCareOf = undefined;
      paymentInfo.checkSameAsAuctionAddress = false;
      paymentInfo.checkMailingAddress = new Address();
      paymentInfo.checkMailingFormattedAddress = undefined;
      paymentInfo.efteddNameOnAccount = this.CleanString(paymentInfo.efteddNameOnAccount);
      paymentInfo.eftFinancialInstitutionNumber = undefined;
      paymentInfo.eftTransitNumber = undefined;
      paymentInfo.eftAccountNumber = undefined;
      paymentInfo.eddRoutingNumber = this.CleanString(paymentInfo.eddRoutingNumber);
      paymentInfo.eddAccountNumber = this.CleanString(paymentInfo.eddAccountNumber);
    } else if (paymentInfo.paymentMethod === PaymentMethodEnum.CanadaCheque ||
      paymentInfo.paymentMethod === PaymentMethodEnum.UnitedStatesCheck) {


      paymentInfo.checkNameCompany = this.CleanString(paymentInfo.checkNameCompany);
      paymentInfo.checkCareOf = this.CleanString(paymentInfo.checkCareOf);

      if (paymentInfo.checkSameAsAuctionAddress) {
        paymentInfo.checkMailingAddress = new Address();
        paymentInfo.checkMailingFormattedAddress = undefined;
      }

      paymentInfo.efteddNameOnAccount = undefined;
      paymentInfo.eftFinancialInstitutionNumber = undefined;
      paymentInfo.eftTransitNumber = undefined;
      paymentInfo.eftAccountNumber = undefined;
      paymentInfo.eddRoutingNumber = undefined;
      paymentInfo.eddAccountNumber = undefined;
    }

    this._contract.paymentInfo = paymentInfo;
  }
  get ContractPaymentInfo(): PaymentInfo {
    return this._contract.paymentInfo;
  }

  set SignContractInfo(signcontractInfo: SignContractInfo) {

    if (signcontractInfo.forwardToSign) {
      signcontractInfo.forwardEmail = this.CleanString(signcontractInfo.forwardEmail);
      signcontractInfo.forwardName = this.CleanString(signcontractInfo.forwardName);
    } else {
      signcontractInfo.forwardEmail = undefined;
      signcontractInfo.forwardName = undefined;
    }
    signcontractInfo.sellersFullName = this.CleanString(signcontractInfo.sellersFullName);

    this._contract.signcontractInfo = signcontractInfo;
  }
  get SignContractInfo(): SignContractInfo {
    return this._contract.signcontractInfo;
  }

  private handleGetError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // console.log ('ERROR:');
      // console.error(error); // log to console instead
      // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private handlePostError (operation = 'operation') {
    return (error: any): Observable<ContractApiResponse> => {

      // console.log ('ERROR:');
      // console.error(error); // log to console instead
      // console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return new Observable<ContractApiResponse>();
    };
  }

  private CleanString (value: string): string {
    if (value) {
      if (value.trim().length === 0) {
        return undefined;
      } else {
        return value.trim();
      }
    } else {
      return undefined;
    }
  }

}
