import { Component, OnInit } from '@angular/core';
import { ContractService } from 'src/app/services/contract.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Contract, ContractApiResponse, AuctionTypeEnum } from 'src/app/models/contract';
import { environment } from 'src/environments/environment';
import { NavigationEnd } from '@angular/router'

declare global {
  interface Window { dataLayer: any[]; }
}

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.css']
})


export class CompleteComponent implements OnInit {

  private sessionId: string;
  public loaded = false;
  public errorstate = false;
  public errormsg = '';
  public contractApiResponse: ContractApiResponse;

  AuctionTypeEnum = AuctionTypeEnum;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contractSvc: ContractService
              ) { }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  ngOnInit() {
    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');


    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.router.navigate(['/']);
    }

    if (this.contractSvc.Contract) {
      this.contractSvc.SaveAfterSignature().subscribe(
        data => {
          this.contractApiResponse = data;
          if (this.contractApiResponse.status === 'failure') {
            this.errorstate = true;
            this.loaded = false;
          } else {
            this.loaded = true;
          }
        },
        error => {
            this.errorstate = true;
            this.errormsg = error;
            this.loaded = false;
        });
    }

    var contact = this.contractSvc.Contract.contactInfo.primaryContact;
    var address = this.contractSvc.Contract.contactInfo.auctionAddress;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event':'form_complete',
      'enhanced_conversion_data': {
        "email": contact.email,
        "phone_number": contact.phone,
        "first_name": contact.firstname,
        "last_name": contact.lastname,
        "home_address": {
          "street": address.address,
          "city": address.cityTown,
          "region": address.stateProvince,
          "postal_code": address.postalZip,
          "country": address.country
        }
      }
    });

  }

}