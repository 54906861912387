/// <reference types="@types/googlemaps" />
import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractService } from 'src/app/services/contract.service';
import { ContactInfo,
         FormattedAddress,
         ContactMethodEnum,
         AddressCountryEnum,
         Contract,
         ContractStatusEnum} from 'src/app/models/contract';
import { StateProvinceService, StateProvince } from 'src/app/services/stateprovince.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verifyaddress',
  templateUrl: './verifyaddress.component.html',
  styleUrls: ['./verifyaddress.component.css']
})
export class VerifyAddressComponent implements OnInit {

  ContactMethodEnum = ContactMethodEnum;

  AddressCountryEnum = AddressCountryEnum;

  contactData = new ContactInfo();
  loaded = true;
  staticMapKey = '';

  private sessionId: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private contractSvc: ContractService,
              private stateProvinceSvc: StateProvinceService) {

  }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  ngOnInit() {

    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.router.navigate(['/']);
    }

    this.contactData = this.contractSvc.ContractContactInfo || new ContactInfo(); // null coalescing operator

    this.staticMapKey = environment.mapsAPI;

  }

  getStateList(): StateProvince[] {
    return this.stateProvinceSvc.getStateList();
  }

  getProvinceList(): StateProvince[] {
    return this.stateProvinceSvc.getProvinceList();
  }

  goToPrevious() {
    this.router.navigate(['/'], { queryParams: { sessionId: this.sessionId } });
  }

  onSubmit() {

      this.router.navigate(['/auction'], { queryParams: { sessionId: this.sessionId } });
  }
}
