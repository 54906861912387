import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EasilyGtmService } from 'easily-gtm';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MaxSold Contract';
  isDevelopment = environment.development;

  constructor(router: Router, private egtm: EasilyGtmService) {
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      let pagename = router.url.substring(0, router.url.indexOf('?'));
      if (!pagename) {
        pagename = '/contactinfo';
      }

      // console.log('push to google:' + pagename );
      //this.egtm.push('vpageview', {'page': pagename});
      else {
       gtag('config', 'UA-30114365-1',{'page_path': pagename});
      }
    });
  }

}
