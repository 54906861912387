import { Injectable } from '@angular/core';
import { IKeyValuePair } from '../models/contract';
import { ReferralSupportedByEnum, ReferralHearAboutUsEnum } from 'src/app/models/contract';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  private _supportedByList: IKeyValuePair[];
  private _hearAboutUsList: Array<any>;

  constructor() {

    //List to display supported by options in the referral info page
    this._supportedByList = [
        //{ key: ReferralSupportedByEnum.Myself, value: 'Myself'}, Removed (08/25/2020)
        { key: ReferralSupportedByEnum.MoveManager, value: 'Move Manager'},
        { key: ReferralSupportedByEnum.Realtor, value: 'Realtor'},
        //{ key: ReferralSupportedByEnum.MaxSoldRep, value: 'MaxSold Rep'}, Removed (08/25/2020)
        { key: ReferralSupportedByEnum.ProfessionalOrganizer, value: 'Professional Organizer'},
        { key: ReferralSupportedByEnum.Other, value: 'Other'},
        { key: ReferralSupportedByEnum.NotApplicable, value: 'Not Applicable'}
      ];

    //List to display hear about us options in the referral info page
    this._hearAboutUsList = [
        { key: ReferralHearAboutUsEnum.FriendsOrFamily, value: 'Friends or Family', checkValue:false},
        { key: ReferralHearAboutUsEnum.InternetSearch, value: 'Internet Search', checkValue:false},
        { key: ReferralHearAboutUsEnum.SocialMediaAd, value: 'Social Media Ad', checkValue:false},
        { key: ReferralHearAboutUsEnum.BankTrustLawyer, value: 'Bank/Trust Lawyer', checkValue:false},
        { key: ReferralHearAboutUsEnum.ContentSalesCompany, value: 'Content Sales Company', checkValue:false},
        { key: ReferralHearAboutUsEnum.AuctionHouse, value: 'Auction House', checkValue:false},
        { key: ReferralHearAboutUsEnum.DirectMailOrPostcard, value: 'Direct Mail or Postcard', checkValue:false},
        { key: ReferralHearAboutUsEnum.ExistingBidder, value: 'Existing Bidder', checkValue:false},
        { key: ReferralHearAboutUsEnum.NAPOPOCProfessionalOrganizer, value: 'NAPO/ POC/ Professional Organizer', checkValue:false},
        { key: ReferralHearAboutUsEnum.NASMMMoveManager, value: 'NASMM/ Move Manager', checkValue:false},
        { key: ReferralHearAboutUsEnum.Realtor, value: 'Realtor', checkValue:false},
        { key: ReferralHearAboutUsEnum.MaxSoldEmployee, value: 'MaxSold Employee', checkValue:false},
        { key: ReferralHearAboutUsEnum.EstateSalesCompany, value: 'Estate Sales Company', checkValue:false},
        { key: ReferralHearAboutUsEnum.TVAd, value: 'TV Ad', checkValue:false},
        { key: ReferralHearAboutUsEnum.Radio, value: 'Radio', checkValue:false},
        { key: ReferralHearAboutUsEnum.RepeatCustomer, value: 'Repeat Customer', checkValue:false},
        { key: ReferralHearAboutUsEnum.Other, value: 'Other', checkValue:false},
        // { key: ReferralHearAboutUsEnum.ReferralCode, value: 'Referral Code'}, Removed 4/11/2019
      ];
  }

  get SupportedByList(): IKeyValuePair[] {
    return this._supportedByList;
  }

  get HearAboutUsList(): Array<any> {
    return this._hearAboutUsList;
  }
}
