import * as uuid from 'uuid';

// Schema Version 1.0: Initial Release Jan 31, 2019
// Schema Version 1.1: Added eddAccountType
// Schema Version 1.2: Added hearAboutUsReferralCode and ReferralHearAboutUsEnum.ReferralCode
// Schema Version 1.3: Removed phoneAreacode, phonePrefix, phoneSuffix. Added phone
// Schema Version 1.4: Added ScheduleInfo.numofRooms
//                     Removed ScheduleInfo.percentHouseholdForSale, ScheduleInfo.highValueDesc
//                     Removed ScheduleInfo.contentsBusinessIndustrial, ScheduleInfo.contentsAppliances,
//                     Removed ScheduleInfo.contentsGarageTools, ScheduleInfo.contentsComputersElectronics, ScheduleInfo.contentsArt,
//                     Removed ScheduleInfo.contentsFurniture, ScheduleInfo.contentsCollectiblesCoins
//Schema Version 1.5:  Added AuctionInfo.appEmail

export enum AddressCountryEnum {
  Canada = 'CA',
  UnitedStates = 'US'
}

export enum AuctionTypeEnum {
  SellerManaged = 'sellerManaged',
  MaxsoldManaged = 'maxsoldManaged'
}

export enum PicUploadTypeEnum {
  UseBlankDraft = 'useBlankDraft',
  UseApp = 'useApp'
}

export enum AuctionCategoryEnum {
  Downsizing = 'downsizing',
  Moving = 'moving',
  EstateSale = 'estateSale',
  Reseller = 'reseller',
  CharityFundraising = 'charityFundraising',
  BusinessDownsizing = 'businessDownsizing',
  RestaurantLiquidation = 'restaurantLiquidation',
  CommercialLiquidation = 'commercialLiquidation',
  Renovation = 'renovation'
}

export enum AuctionPropertyEnum {
  House = 'house',
  Business = 'business',
  CondoApartment = 'condoApartment',
  Warehouse = 'warehouse',
  Storage = 'storage',
  Other = 'other',
}

export enum ContactMethodEnum {
  Email = 'email',
  Phone = 'phone'
}

export enum ReferralSupportedByEnum {
  //Myself = 'myself',
  MoveManager = 'moveManager',
  ProfessionalOrganizer = 'professionalOrganizer',
  Realtor = 'realtor',
  //MaxSoldRep = 'maxSoldRep',
  Other = 'other',
  NotApplicable = 'notApplicable'
}

export enum PaymentMethodEnum {
  CanadaCheque = 'CACheque',
  CanadaETF = 'CAETF',
  CanadaPaymentInfoOnFile = 'CAOnFile',
  UnitedStatesCheck = 'USCheque',
  UnitedStatesEDD = 'USEDD',
  UnitedStatesPaymentInfoOnFile = 'USOnFile',
}

export enum ReferralHearAboutUsEnum {
  FriendsOrFamily = 'friendsOrFamily',
  InternetSearch = 'internetSearch',
  SocialMediaAd = 'socialMediaAd',
  BankTrustLawyer = 'bankTrustLawyer',
  ContentSalesCompany = 'contentSalesCompany',
  AuctionHouse = 'auctionHouse',
  DirectMailOrPostcard = 'directMailOrPostcard',
  Other = 'other',
  ExistingBidder = 'existingBidder',
  ReferralCode = 'referralCode',
  NAPOPOCProfessionalOrganizer = 'nAPO/POC/ProfessionalOrganizer',
  NASMMMoveManager = 'nASMM/MoveManager',
  Realtor = 'realtor',
  MaxSoldEmployee = 'maxSoldEmployee',
  EstateSalesCompany = 'estateSalesCompany',
  TVAd = 'tVAd',
  Radio = 'radio',
  RepeatCustomer = 'repeatCustomer'
}

export enum ContractStatusEnum {
  Created = 'created',
  ContactInfoCaptured = 'contactInfoCaptured',
  AuctionInfoCaptured = 'auctionInfoCaptured',
  ScheduleInfoCaptured = 'scheduleInfoCaptured',
  PaymentInfoCaptured = 'paymentInfoCaptured',
  ReferralInfoCaptured = 'referralInfoCaptured',
  SentToClientForSignature = 'sentToClientForSignature',
  SignedByClient = 'signedByClient',
}

export enum EDDAccountTypeEnum {
  Checking = 'checking',
  Savings = 'savings'
}

export class ContactDetail {
  firstname: string;
  lastname: string;
  email: string;
  phone: string; // Added 5/15/2019
  // phoneAreacode: string; // Removed 5/15/2019
  // phonePrefix: string; // Removed 5/15/2019
  // phoneSuffix: string; // Removed 5/15/2019
}

export class Address {
  address: string;
  unitSuite: string;
  cityTown: string;
  stateProvince: string;
  postalZip: string;
  country: AddressCountryEnum; // changed string datatype for 1.4
  specialInstructions: string;
}



export class ContactInfo {
  primaryContact: ContactDetail;
  contactMethod: ContactMethodEnum;
  isRepeatSeller: boolean;
  hasSecondaryContact: boolean;
  secondaryContact: ContactDetail;
  auctionAddress: Address;
  auctionFormattedAddress: FormattedAddress;

  constructor() {
    this.primaryContact = new ContactDetail();
    this.contactMethod = ContactMethodEnum.Phone;
    this.isRepeatSeller = false;
    this.hasSecondaryContact = false;
    this.secondaryContact = new ContactDetail();
    this.auctionAddress = new Address();
    this.auctionAddress.country = AddressCountryEnum.Canada; // Added in 1.4
  }
}

export class AuctionInfo {
  auctionType: AuctionTypeEnum;
  picuploadType: PicUploadTypeEnum;
  numberOfLots: number;
  goneByDate: Date;
  auctionCategory: AuctionCategoryEnum;
  auctionProperty: AuctionPropertyEnum;
  hoaMgmtApproved: boolean;
  showStreetNumber: boolean;
  appEmail: string; //Added with spec 1.5 (2020-08-13)

  constructor () {
    this.auctionType = null;
    this.showStreetNumber = false;
  }

}

export class ScheduleInfo {
  propertySqft: number;
  numofRooms: number; // added with v1.4
  // percentHouseholdForSale: number; removed with 1.4
  // contentsBusinessIndustrial: number; removed with 1.4
  // contentsAppliances: number; removed with 1.4
  // contentsGarageTools: number; removed with 1.4
  // contentsComputersElectronics: number; removed with 1.4
  // contentsArt: number; removed with 1.4
  // contentsFurniture: number; removed with 1.4
  // contentsCollectiblesCoins: number; removed with 1.4
  // highValueDesc: string; removed with 1.4
  preferredCatalogingDate: Date;
  propertyAccessDesc: string;
  isGatedCommunity: boolean;
  temporaryGateCode: string;
  envConditionPetHome: boolean;
  envConditionSmokerHome: boolean;
  envConditionAirConditioned: boolean;
  envConditionNoWashroom: boolean;
  envConditionNoUtilities: boolean;
  envConditionHomeIsUnoccupied: boolean;
  envConditionMoldWaterDamageEvident: boolean;

  constructor () {
/*     this.percentHouseholdForSale = 0;
    this.contentsBusinessIndustrial = 0;
    this.contentsAppliances = 0;
    this.contentsGarageTools = 0;
    this.contentsComputersElectronics = 0;
    this.contentsArt = 0;
    this.contentsFurniture = 0;
    this.contentsCollectiblesCoins = 0; */
    this.isGatedCommunity = false;
    this.envConditionPetHome = false;
    this.envConditionSmokerHome = false;
    this.envConditionAirConditioned = false;
    this.envConditionNoWashroom = false;
    this.envConditionNoUtilities = false;
    this.envConditionHomeIsUnoccupied = false;
    this.envConditionMoldWaterDamageEvident = false;
  }

}

export class ReferralInfo {
  supportedBy: ReferralSupportedByEnum;
  supportedByCompany: string;
  supportedByMaxsoldRepId: string;
  supportedByContact: ContactDetail;
  hearAboutUs: ReferralHearAboutUsEnum;
  hearAboutUsStr: string;
  // hearAboutUsObj: Array<object> = [
  //   {key:'friendsOrFamily', value:false},
  //   {key:'internetSearch', value:false},
  //   {key:'socialMediaAd', value:false},
  //   {key: 'bankTrustLawyer', value:false},
  //   {key:'contentSalesCompany', value:false}
  //   // {AuctionHouse = 'auctionHouse'},
  //   // {DirectMailOrPostcard = 'directMailOrPostcard'},
  //   // {Other = 'other'},
  //   // {ExistingBidder = 'existingBidder'},
  //   // {ReferralCode = 'referralCode'},
  //   // {NAPOPOCProfessionalOrganizer = 'nAPO/POC/ProfessionalOrganizer'},
  //   // {NASMMMoveManager = 'nASMM/MoveManager'},
  //   // {Realtor = 'realtor'},
  //   // {MaxSoldEmployee = 'maxSoldEmployee'},
  //   // {EstateSalesCompany = 'estateSalesCompany'},
  //   // {TVAd = 'tVAd'},
  //   // {Radio = 'radio'},
  //   // {RepeatCustomer = 'repeatCustomer'}
  // ];
  hearAboutUsOtherDesc: string;
  // hearAboutUsReferralCode is not apart of hearAboutUs section anymore.
  // It's broken out separately. Kept the name to ensure no database changes.
  hearAboutUsReferralCode: string;

  constructor () {
    this.supportedBy = ReferralSupportedByEnum.NotApplicable;
    this.supportedByContact = new ContactDetail();
  }

}

export class PaymentInfo {
  paymentMethod: PaymentMethodEnum;
  checkNameCompany: string;
  checkAddToTheEstateOf = false;
  checkCareOf: string;
  checkSameAsAuctionAddress = false;
  checkMailingAddress: Address;
  checkMailingFormattedAddress: FormattedAddress;
  efteddNameOnAccount: string;
  eftFinancialInstitutionNumber: string;
  eftTransitNumber: string;
  eftAccountNumber: string;
  eddRoutingNumber: string;
  eddAccountNumber: string;
  eddAccountType: EDDAccountTypeEnum;

  constructor () {
    this.checkAddToTheEstateOf = false;
    this.checkSameAsAuctionAddress = false;
    this.checkMailingAddress = new Address();
    this.checkMailingAddress.country = AddressCountryEnum.Canada; // Added in 1.4
  }
}

export class SignContractInfo {
  forwardName: string;
  forwardFirstName: string;
  forwardLastName: string;
  forwardEmail: string;
  forwardToSign: boolean;
  dateOfSignature: Date;
  sigFont: string;
  fullnameText: string;
  initialsText: string;
  initialsSvg: string;
  fullnameSvg: string;
  sellersFullName: string;
  initialsPng: string;
  fullnamePng: string;
  contractVersion: string;

  constructor () {
    this.forwardToSign = false;
    this.sigFont = 'Shallou.otf';
    this.contractVersion = 'v20200501';
  }

}

export class FormattedAddress {
  autocompletedAddress: string;
  street: string;
  streetNumber: string;
  cityTown: string;
  stateProvince: string;
  country: string;
  postalZip: string;
  lat: string;
  lng: string;
}

export interface IKeyValuePair {
  key: string;
  value: string;
}

export class ContractApiResponse {
    status: string;
    contractNumber: string;
    contractDownloadURL: string;
}

export class Contract {
  schemaversion: string;
  contractNumber: string;
  contractGuid: string;
  contractStatus: ContractStatusEnum;
  createdDate: Date;
  modifiedDate: Date;
  localDate: string;
  contactInfo: ContactInfo;
  auctionInfo: AuctionInfo;
  scheduleInfo: ScheduleInfo;
  referralInfo: ReferralInfo;
  paymentInfo: PaymentInfo;
  signcontractInfo: SignContractInfo;

  constructor () {
    this.schemaversion = '1.5';
    this.contractGuid = uuid.v4();
    this.contractStatus = ContractStatusEnum.Created;
    this.createdDate = new Date();
    this.modifiedDate = this.createdDate;
    this.contactInfo = new ContactInfo();
    this.auctionInfo = new AuctionInfo();
    this.scheduleInfo = new ScheduleInfo();
    this.referralInfo = new ReferralInfo();
    this.paymentInfo = new PaymentInfo();
    this.signcontractInfo = new SignContractInfo();
  }
}
