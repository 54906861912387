/// <reference types="@types/googlemaps" />
import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContractService } from 'src/app/services/contract.service';
import { ContactInfo,
         FormattedAddress,
         ContactMethodEnum,
         AddressCountryEnum,
         Contract,
         ContractStatusEnum} from 'src/app/models/contract';
import { StateProvinceService, StateProvince } from 'src/app/services/stateprovince.service';
import * as uuid from 'uuid';
import { EasilyGtmService } from 'easily-gtm';
import { CookieService } from '../../services/cookie.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contactinfo',
  templateUrl: './contactinfo.component.html',
  styleUrls: ['./contactinfo.component.css']
})
export class ContactInfoComponent implements OnInit {

  ContactMethodEnum = ContactMethodEnum;

  AddressCountryEnum = AddressCountryEnum;

  contactData = new ContactInfo();
  loaded = true;
  confirm_Email="";
  utm_tag: string;
  utm_source: any;
  utm_campaign: any;
  utm_medium: any;
  utm_content: any;
  utm_term: any;

  private sessionId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private contractSvc: ContractService,
    private stateProvinceSvc: StateProvinceService,
    private egtm: EasilyGtmService,
    public cookie:CookieService,
    private http: HttpClient) { 

  }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  ngOnInit() {
    // capture utm tags
    this.utm_tag = decodeURIComponent(this.cookie.getCookie('__gtm_campaign_url'));
    this.utm_source = this.utm_tag.match(new RegExp('utm_source=([^&]+)'));
    this.utm_campaign = this.utm_tag.match(new RegExp('utm_campaign=([^&]+)'));
    this.utm_content = this.utm_tag.match(new RegExp('utm_content=([^&]+)'));
    this.utm_medium = this.utm_tag.match(new RegExp('utm_medium=([^&]+)'));
    this.utm_term = this.utm_tag.match(new RegExp('utm_term=([^&]+)'));

    // if there's a token, then retrieve the existing contract from the backend.
    const token = this.route.snapshot.queryParamMap.get('token');

    if (token) {
      this.loaded = false;
    }

    const partial = this.route.snapshot.queryParamMap.get('partial');

    if (partial) {
      this.loaded = false;
    }

    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.sessionId = uuid.v4();
      this.contractSvc.CreateContract();
    }

    // this ID is generated from the API. If it has a value, we use this to pull the contract
    // from the backend using this token. This token won't be the internal contract.contractGuid or sessionId. It will be a masked
    // token  that will expire.
    if (token) {
      this.contractSvc.ViewContract(token).subscribe(
        data => {
          // console.log(data);
          this.contractSvc.SaveSessionWithContract (this.sessionId, data);
          this.loaded = true;

          this.contractSvc.Contract.modifiedDate = new Date();
          this.contactData = this.contractSvc.ContractContactInfo;

        },
        error => {
            // console.log (error);
            this.loaded = true;
        });
    } else if (partial) {

          this.contractSvc.ViewPartialContract(partial).subscribe(
            data => {
              // console.log(data);
              this.contractSvc.SaveSessionWithContract (this.sessionId, data);
              this.loaded = true;

              this.contractSvc.Contract.modifiedDate = new Date();
              this.contactData = this.contractSvc.ContractContactInfo;

            },
            error => {
                // console.log (error);
                this.loaded = true;
            });
    } else {
      this.contactData = this.contractSvc.ContractContactInfo;
    }

    // if a referral code is passed, store it in the referall code field https://client.maxsold.com/?ref=FB2019
    const referralcode = this.route.snapshot.queryParamMap.get('ref');
    if (referralcode) {
      this.contractSvc.ReferralCode = referralcode;
    }

  }

  getStateList(): StateProvince[] {
    return this.stateProvinceSvc.getStateList();
  }

  getProvinceList(): StateProvince[] {
    return this.stateProvinceSvc.getProvinceList();
  }

  setAddress(fa: FormattedAddress) {

    // We are wrapping this in a NgZone to reflect the changes to the object in the DOM.
    this.ngZone.run(() => {
      if (fa) {
        if (fa.streetNumber) {
          this.contactData.auctionAddress.address = fa.streetNumber + ' ' + fa.street;
        } else {
          this.contactData.auctionAddress.address = fa.street;
        }
        this.contactData.auctionAddress.cityTown = fa.cityTown;
        this.contactData.auctionAddress.stateProvince = fa.stateProvince;
        this.contactData.auctionAddress.postalZip = fa.postalZip;
        // this.contactData.auctionAddress.country = fa.country;
        this.contactData.auctionFormattedAddress = fa;
      }
    });
  }

  resetAddress()
  {
    this.contactData.auctionAddress.address = null;
    this.contactData.auctionAddress.cityTown = null;
    this.contactData.auctionAddress.stateProvince = null;
    this.contactData.auctionAddress.postalZip = null;
    this.contactData.auctionAddress.unitSuite = null;
    this.contactData.auctionFormattedAddress = null;
  }

  onSubmit() {

      this.contractSvc.ContractContactInfo = this.contactData;
      this.contractSvc.ContractStatus = ContractStatusEnum.ContactInfoCaptured;
      this.contractSvc.SaveSession(this.sessionId);

      this.contractSvc.SavePartialContract().subscribe();
      if (this.utm_source != null) {
        console.log('utm_url', this.utm_tag)

        this.sendCustomerDetails()
      }

      this.egtm.push('page',  {'page': '/complete'});

      this.router.navigate(['/verifyaddress'], { queryParams: { sessionId: this.sessionId } });

    
  }
/**
 * Functions sends the customer details to zapier which will then send the data to the Master Warehouse
 * All utm tags may not be available, so we need to check for them before pushing to zapier else none of the data will be sent
 * After checking we need to remove the ones that are "undefined" from the object else zapier will not trigger the event
 * */
  sendCustomerDetails() {
       
    let utm_data = {}
    let temp_utm_data = [];
    // check if utm data exists and return NULL as array if not
    let utm_content = this.utm_content ? this.utm_content: ['NULL'];
    let utm_term = this.utm_term ? this.utm_term: ['NULL'];
    let utm_campaign = this.utm_campaign ? this.utm_campaign: ['NULL'];
    let utm_medium = this.utm_medium ? this.utm_medium: ['NULL'];
    let utm_source = this.utm_source ? this.utm_source: ['NULL'];
    // iterate through utm data and add to utm_data object
      for(let i=1; i<this.utm_source.length; i++) {
        if(this.utm_source[i] != null) {
          temp_utm_data.push(utm_data[
            this.utm_source] = utm_source[i],
            this.utm_campaign = utm_campaign[i],
            this.utm_medium = utm_medium[i],
            this.utm_content = utm_content[i],
            this.utm_term = utm_term[i]
            );
        }
      }

    utm_data = {
      'utm_source': temp_utm_data[0],
      'utm_campaign': temp_utm_data[1],
      'utm_medium': temp_utm_data[2],
      'utm_content': temp_utm_data[3],
      'utm_term': temp_utm_data[4]
    };
    // remove undefined values from utm_data
    for (const key in utm_data) {
      if(utm_data[key] == undefined) {
        delete utm_data[key];
      }
    }
    // send utm data to zapier
    const body = {
      firstname: this.contactData.primaryContact.firstname,
      lastname: this.contactData.primaryContact.lastname,
      email: this.contactData.primaryContact.email,
      phone_number: this.contactData.primaryContact.phone,
      address: this.contactData.auctionAddress.address + ' ' +
      this.contactData.auctionAddress.cityTown + ' ' +
      this.contactData.auctionAddress.country + ' ' +
      this.contactData.auctionAddress.stateProvince + ' ' +
      this.contactData.auctionAddress.postalZip,
      utm_data: utm_data
    }
    this.http.post<any>('https://hooks.zapier.com/hooks/catch/11001662/bqo6unv/', body, {headers:
    {
      'Content-Type': 'application/x-www-form-urlencoded'
    }}).subscribe(
      data => {
          console.info(data.status);
      }
    )
  }

  unhideSpecialInstructions() {
    const x = document.getElementById('specialInstructions');
    const y = document.getElementById('note');
    if (x.style.display === "none") {
      x.style.display = "block";
      y.style.display = "block";
    } else {
      x.style.display = "none";
      y.style.display = "none";
    }
  }
}