import { Component, OnInit } from '@angular/core';
import { ReferralInfo, IKeyValuePair, Contract, ContractStatusEnum, ContactDetail } from 'src/app/models/contract';
import { Router, ActivatedRoute  } from '@angular/router';
import { ContractService } from 'src/app/services/contract.service';
import { ReferralService } from 'src/app/services/referral.service';
import { ReferralSupportedByEnum, ReferralHearAboutUsEnum, AuctionTypeEnum } from 'src/app/models/contract';

@Component({
  selector: 'app-referraldetails',
  templateUrl: './referraldetails.component.html',
  styleUrls: ['./referraldetails.component.css']
})
export class ReferralDetailsComponent implements OnInit {

  private sessionId: string;
  private referralInfoArray: Array<string> = [];

  ReferralSupportedByEnum = ReferralSupportedByEnum;
  ReferralHearAboutUsEnum = ReferralHearAboutUsEnum;

  referralData: ReferralInfo;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contractSvc: ContractService,
              private referralSvc: ReferralService) { }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  ngOnInit() {
    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.router.navigate(['/']);
    }

    this.referralData = this.contractSvc.ContractReferralInfo || new ReferralInfo();
  }

  get SupportedByList(): IKeyValuePair[] {
    return this.referralSvc.SupportedByList;
  }

  get HearAboutUsList(): Array<any> {
    return this.referralSvc.HearAboutUsList;
  }

  makeReferralInfoString(){
    this.HearAboutUsList.forEach(hearAboutUsItem=>{
      if(hearAboutUsItem.checkValue){
        this.referralInfoArray.push(hearAboutUsItem.value)
      }
    })
    this.referralData.hearAboutUsStr = this.referralInfoArray.toString();
  }

  goToPrevious() {
    if (this.contractSvc.ContractAuctionInfo.auctionType === AuctionTypeEnum.MaxsoldManaged) {
      this.router.navigate(['/schedule'], { queryParams: { sessionId: this.sessionId } });
    } else { // seller managed
      this.router.navigate(['/auction'], { queryParams: { sessionId: this.sessionId } });
    }
  }

  onSubmit() {

    this.makeReferralInfoString();

    this.contractSvc.ContractReferralInfo = this.referralData;
    this.contractSvc.ContractStatus = ContractStatusEnum.ReferralInfoCaptured;
    this.contractSvc.SaveSession(this.sessionId);

    this.contractSvc.SavePartialContract().subscribe();

    this.router.navigate(['/payment'], { queryParams: { sessionId: this.sessionId } });
  }

}


