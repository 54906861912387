import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';

import { ScheduleInfo, Contract, AuctionInfo, AuctionCategoryEnum, ContractStatusEnum } from 'src/app/models/contract';
import { ContractService } from 'src/app/services/contract.service';
import * as moment from 'moment';

@Component({
  selector: 'app-schedulecatalog',
  templateUrl: './schedulecatalog.component.html',
  styleUrls: ['./schedulecatalog.component.css']
})
export class ScheduleCatalogComponent implements OnInit {

  private sessionId: string;

  scheduleData: ScheduleInfo;
  auctionData: AuctionInfo;
  AuctionCategoryEnum = AuctionCategoryEnum;

  preferredCatalogingCalendarDate: Date;
  minDate: Date;
  maxDate: Date;
  disabledDates: Date[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private contractSvc: ContractService) {
  }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  ngOnInit() {
    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.router.navigate(['/']);
    }

    this.scheduleData = this.contractSvc.ContractScheduleInfo || new ScheduleInfo(); // null coalescing operator
    this.auctionData = this.contractSvc.ContractAuctionInfo;

    this.minDate = this.addBusinessDays(new Date(), 3).toDate();

    if (this.auctionData.goneByDate) {
      this.maxDate = moment(this.auctionData.goneByDate).subtract(3, 'd').toDate();

    } else {
      this.maxDate = moment(this.contractSvc.Contract.createdDate).add(87, 'd').toDate();
    }

    // check to see if the preferred Cataloging Date is still valid if
    // it was previously saved. If it's still valid, then assign the preferred Cataloging Date.
    if (this.scheduleData.preferredCatalogingDate) {
      this.preferredCatalogingCalendarDate = null;
      const moment_prefCatalogingDate = moment(this.scheduleData.preferredCatalogingDate);
      if (moment_prefCatalogingDate.isSameOrAfter(moment(this.minDate)) && moment_prefCatalogingDate.isSameOrBefore(moment(this.maxDate))) {
        this.preferredCatalogingCalendarDate = new Date(this.scheduleData.preferredCatalogingDate);
      }
    }

  }

  addBusinessDays(date, daysToAdd) {
    let cnt = 0;
    let tmpDate = moment(date);
    while (cnt < daysToAdd) {
        tmpDate = tmpDate.add('days', 1);
        if (tmpDate.weekday() != moment().day('Sunday').weekday() && tmpDate.weekday() != moment().day('Saturday').weekday()) {
            cnt = cnt + 1;
        }
    }

    return tmpDate;
}

  goToPrevious() {
    this.router.navigate(['/auction'], { queryParams: { sessionId: this.sessionId } });
  }

  onSubmit() {

    this.scheduleData.preferredCatalogingDate = moment.utc(this.preferredCatalogingCalendarDate).local().toDate();

    this.contractSvc.ContractScheduleInfo = this.scheduleData;
    this.contractSvc.ContractStatus = ContractStatusEnum.ScheduleInfoCaptured;
    this.contractSvc.SaveSession(this.sessionId);

    this.contractSvc.SavePartialContract().subscribe();

    this.router.navigate(['/referral'], { queryParams: { sessionId: this.sessionId } });
  }



}
