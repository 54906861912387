import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractService } from 'src/app/services/contract.service';
import { ContactInfo,
         PaymentInfo,
         FormattedAddress,
         AuctionInfo,
         PaymentMethodEnum,
         AddressCountryEnum,
         EDDAccountTypeEnum,
         Contract,
         ContractStatusEnum} from 'src/app/models/contract';
import { StateProvinceService, StateProvince } from 'src/app/services/stateprovince.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  private sessionId: string;

  // AuctionTypeEnum = AuctionTypeEnum;
  PaymentMethodEnum = PaymentMethodEnum;
  AddressCountryEnum = AddressCountryEnum;
  EDDAccountTypeEnum = EDDAccountTypeEnum;

  paymentData: PaymentInfo;
  contactData: ContactInfo;
  auctionData: AuctionInfo;
  contractCountry: AddressCountryEnum;
  isRepeatSeller: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private contractSvc: ContractService,
              private stateProvinceSvc: StateProvinceService) {
  }

  get AllContractData(): Contract {
    return this.contractSvc.Contract;
  }

  ngOnInit() {
    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (!this.contractSvc.LoadSession(this.sessionId)) {
      this.router.navigate(['/']);
    }

    this.paymentData = this.contractSvc.ContractPaymentInfo || new PaymentInfo(); // null coalescing operator
    this.contactData = this.contractSvc.ContractContactInfo;
    this.auctionData = this.contractSvc.ContractAuctionInfo;
    this.contractCountry = this.contactData.auctionAddress.country;
    this.isRepeatSeller = this.contactData.isRepeatSeller;
  }

  defaultcheckNameCompany() {
    if ((this.paymentData.checkNameCompany === undefined) ||
      (this.paymentData.checkNameCompany === null) ||
      (this.paymentData.checkNameCompany.trim() === '')) {

        if (this.contactData.primaryContact.firstname !== undefined &&
          this.contactData.primaryContact.firstname.trim() !== '' &&
          this.contactData.primaryContact.lastname !== undefined &&
          this.contactData.primaryContact.lastname.trim() !== '') {

            this.paymentData.checkNameCompany = this.contactData.primaryContact.firstname +
                                                ' ' + this.contactData.primaryContact.lastname;
          }
      }
  }

  getStateList(): StateProvince[] {
    return this.stateProvinceSvc.getStateList();
  }

  getProvinceList(): StateProvince[] {
    return this.stateProvinceSvc.getProvinceList();
  }

  setAddress(fa: FormattedAddress) {
    // We are wrapping this in a NgZone to reflect the changes to the object in the DOM.
    this.ngZone.run(() => {
      if (fa) {
        if (fa.streetNumber) {
          this.paymentData.checkMailingAddress.address = fa.streetNumber + ' ' + fa.street;
        } else {
          this.paymentData.checkMailingAddress.address = fa.street;
        }
        this.paymentData.checkMailingAddress.cityTown = fa.cityTown;
        this.paymentData.checkMailingAddress.stateProvince = fa.stateProvince;
        this.paymentData.checkMailingAddress.postalZip = fa.postalZip;
        this.paymentData.checkMailingFormattedAddress = fa;
      }
    });
  }

  clearMailingAddress() {

  }

  sameAddressFunction(){
    if(this.paymentData.checkSameAsAuctionAddress){
      this.paymentData.checkMailingAddress.country = AddressCountryEnum.Canada
    }
  }

  goToPrevious() {
    this.router.navigate(['/referral'], { queryParams: { sessionId: this.sessionId } });
  }


  onSubmit() {

    this.contractSvc.ContractPaymentInfo = this.paymentData;
    this.contractSvc.ContractStatus = ContractStatusEnum.PaymentInfoCaptured;
    this.contractSvc.SaveSession(this.sessionId);

    this.contractSvc.SavePartialContract().subscribe();

    this.router.navigate(['/contract'], { queryParams: { sessionId: this.sessionId } });
  }

}
